import React from 'react';
import PropTypes from 'prop-types';
import { htmlParser } from '../helpers/htmlParser';

const CourseGroupTimes = ({ groups }) => {
  return (
    <div className='courses-group-container'>
      {groups.map((group, idx) => (
        <div className="courses-group-info" key={idx}>
          <div className="courses-group-name">{group.name}</div>
          <div>{htmlParser(group.text)}</div>
        </div>
      ))}
    </div>
  );
};

CourseGroupTimes.propTypes = {
    groups: PropTypes.any
};

export default CourseGroupTimes;
