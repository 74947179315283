import React from 'react';
import PropTypes from 'prop-types';
import CourseGroupTimes from './CourseGroupTimes';

const LocationCard = ({ location, colour }) => {
  return (
    <div className="column is-6 is-centered">
      <article
        className={`location-card ${colour} is-flex is-flex-direction-column is-justify-content-between`}
      >
        <div className="location-card-content-container">
          <div className="has-text-centered">
            <h3 className="location-heading">{location.name}</h3>

            <div className="section-info-heading">
              <div>{location.frequency}</div>
              <div>{location.duration}</div>
            </div>
          </div>

          <CourseGroupTimes groups={location.groups} />
        </div>
        <a
          className="coordinates-text-container is-flex is-align-items-center"
          href={location.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={`coordinates-btn coordinates-btn-${colour} is-flex is-align-items-center`}>
            <span className={`coordinates-link-${colour}`}>
              <b>Local</b>
            </span>
            <img
              src="/img/map.png"
              alt="description"
              className="img-map-icon"
            />
          </div>
        </a>
      </article>
    </div>
  );
};

LocationCard.propTypes = {
  location: PropTypes.object
};

export default LocationCard;
