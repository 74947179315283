import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import { htmlParser } from '../helpers/htmlParser';
import TitleImg from '../components/TitleImg';
import LocationCard from '../components/LocationCard';

// eslint-disable-next-line
export const CoursesPageTemplate = ({
  title,
  sectionOne,
  sectionTwo,
  sectionThree,
  sectionInfo,
  ctaSection,
  advanced
}) => {
  return (
    <div>
      <section className="section section-courses-top section-gradient">
        <div className="container">
          <div className="content">
            <div className="columns is-vcentered is-centered">
              <div className="column is-10 is-offset-1 is-centered-content">
                <TitleImg
                  title={title}
                  titleClass={'courses-title'}
                  imgSrc={'/img/dog-graduation.png'}
                  imgClass={'courses-title-img'}
                />
                <div className="columns is-vcentered is-centered content-block">
                  <div className="column is-6 is-vcentered is-centered-content text-column">
                    <h3 className="courses-heading">{sectionOne.heading}</h3>
                    <div>{htmlParser(sectionOne.text)}</div>
                  </div>
                  <div className="column is-6 image-column">
                    <PreviewCompatibleImage
                      className="about-img"
                      imageInfo={sectionOne.image}
                      imgAlt={sectionOne.alt}
                    />
                  </div>
                </div>
                <div className="columns is-vcentered content-block">
                  <div className="column is-6 is-vcentered is-centered image-column">
                    <PreviewCompatibleImage
                      className="about-img"
                      imageInfo={sectionTwo.image}
                      imgAlt={sectionTwo.alt}
                    />
                  </div>
                  <div className="column is-6 is-vcentered is-centered is-centered-content text-column">
                    <h3 className="courses-heading">{sectionTwo.heading}</h3>
                    <div>{htmlParser(sectionTwo.text)}</div>
                  </div>
                </div>
                <div className="columns is-vcentered content-block">
                  <div className="column is-6 is-centered-content text-column">
                    <h3 className="courses-heading">{sectionThree.heading}</h3>
                    <div>{htmlParser(sectionThree.text)}</div>
                  </div>
                  <div className="column is-6 image-column">
                    <PreviewCompatibleImage
                      className="about-img"
                      imageInfo={sectionThree.image}
                      imgAlt={sectionThree.alt}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section--gradient">
        <div className="container">
          <div className="content">
            <div className="columns is-vcentered is-centered">
              <div className="column is-10 is-offset-1">
                <TitleImg
                  title={sectionInfo.title}
                  titleClass={'courses-title'}
                  imgSrc={'/img/horarios-title-img.png'}
                  imgClass={'courses-title-img'}
                />
                <div className="columns">
                  <div className="column is-10 is-offset-1">
                    <div className="columns is-centered ">
                      <LocationCard
                        location={sectionInfo.locationOne}
                        colour="location-card-one"
                      />
                      <LocationCard
                        location={sectionInfo.locationTwo}
                        colour="location-card-two"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section sign-up-section section--gradient">
        <div className="container">
          <div className="content">
            <div className="columns is-vcentered is-centered">
              <div className="column is-10 is-offset-1">
                <TitleImg
                  title={ctaSection.title}
                  titleClass={'courses-title'}
                  imgSrc={'/img/inscricoes-title-img.png'}
                  imgClass={'courses-title-img'}
                />
                <div className="columns is-vcentered is-centered">
                  <div className="column is-6 is-vcentered is-centered-content">
                    <h3 className="courses-heading">{ctaSection.heading}</h3>
                    <div>{htmlParser(ctaSection.text)}</div>
                  </div>
                  <div className="column is-6">
                    <PreviewCompatibleImage
                      className="sign-up-img"
                      imageInfo={ctaSection.image}
                      imgAlt={ctaSection.alt}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section--gradient">
        <div className="container">
          <div className="content">
            <div className="columns is-vcentered is-centered">
              <div className="column is-10 is-offset-1">
                <TitleImg
                  title={advanced.title}
                  titleClass={'courses-title'}
                  imgSrc={'/img/medal.png'}
                  imgClass={'courses-title-img'}
                />
                <div>{htmlParser(advanced.text)}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

CoursesPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  sectionOne: PropTypes.object,
  sectionTwo: PropTypes.object,
  sectionThree: PropTypes.object,
  sectionInfo: PropTypes.object,
  ctaSection: PropTypes.object,
  advanced: PropTypes.object
};

const CoursesPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <CoursesPageTemplate
        title={frontmatter.title}
        sectionOne={frontmatter.sectionOne}
        sectionTwo={frontmatter.sectionTwo}
        sectionThree={frontmatter.sectionThree}
        sectionInfo={frontmatter.sectionInfo}
        ctaSection={frontmatter.ctaSection}
        advanced={frontmatter.advanced}
      />
    </Layout>
  );
};

CoursesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default CoursesPage;

export const coursesPageQuery = graphql`
  query CoursesPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "courses-page" } }) {
      frontmatter {
        title
        sectionOne {
          image {
            childImageSharp {
              gatsbyImageData(height: 320, quality: 100, layout: CONSTRAINED)
            }
          }
          alt
          heading
          text
        }
        sectionTwo {
          image {
            childImageSharp {
              gatsbyImageData(height: 320, quality: 100, layout: CONSTRAINED)
            }
          }
          alt
          heading
          text
        }
        sectionThree {
          image {
            childImageSharp {
              gatsbyImageData(height: 320, quality: 100, layout: CONSTRAINED)
            }
          }
          alt
          heading
          text
        }
        sectionInfo {
          title
          locationOne {
            name
            coordinates
            link
            frequency
            duration
            groups {
              name
              text
            }
          }
          locationTwo {
            name
            coordinates
            link
            frequency
            duration
            groups {
              name
              text
            }
          }
        }
        ctaSection {
          title
          image {
            childImageSharp {
              gatsbyImageData(height: 240, quality: 100, layout: CONSTRAINED)
            }
          }
          alt
          heading
          text
        }
        advanced {
          title
          text
        }
      }
    }
  }
`;
